<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="row">
          <div class="col-2" @click="informationvorsorgezuinformation()">
            <img src="../assets/icons/vector_back.svg">
            <!-- wenn kein Icon, img entfernen -->
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-white">Vorsorge</p>
          </div>
          <div class="col-2 col-right">
            <!-- wenn kein Icon, img entfernen -->
          </div>
        </div>
      </div>
    </div>

    <div class="accordion-body margintop140 container marginbottom20">
      <p class="pregular fontcolor-white opacity80">Die richtige Prävention ist wichtig, um Risiken und Nebenwirkungen vorzubeugen.</p>
    </div>

    <div class="container paddingbottom110">
      <div class="accordion" role="tablist">
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordionvorsorge1 variant="info">
              <p class="psemibold fontcolor-white">
                Körperpflege
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordionvorsorge1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Die persönliche Körperpflege ist selbstverständlich, wobei das Duschen dem Baden vorzuziehen ist. Ein Bad sollte erst nach vollständigem Verheilen der Operationsnarbe vorgenommen werden. Sorgfältige Händehygiene vor dem Essen ist wichtig. Durch häufiges Händewaschen kommt es zum Austrocknen der Haut, deshalb empfehlen wir die Benutzung einer Pflegecreme. Achten Sie auf eine sorgfältige Fuß- und Nagelpflege. Vermeiden Sie Verletzungen. Ihre Toilettenartikel sollten nur Sie benutzen. Größte Sorgfalt bei der Hygiene nach Toilettengängen ist zu beachten. Vorsicht vor Verletzungen bei Nassrasuren. Bei der Zahnpflege sollten Sie eine weiche Zahnbürste benutzen, um Verletzungen zu vermeiden. Unter Einnahme der Immunsuppression kann es zu Zahnfleischwucherungen kommen. Die Zähne sollten nach jeder Mahlzeit gereinigt und alle Speisereste am Zahnfleischrand sorgfältig entfernt werden. Speisereste fördern die Bildung von Wucherungen.
              </p>
            </b-card-body>
          </b-collapse>
          <div></div>
        </b-card>
        
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordionvorsorge2 variant="info">
              <p class="psemibold fontcolor-white">
                Hautpflege
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordionvorsorge2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Verwenden Sie täglich eine Sonnenschutzcreme mit hohem Lichtschutzfaktor (LF 50+ = sehr hoher UV-Schutz). Meiden Sie die Mittagssonne zwischen 11:00 und 15:00 Uhr. Vergessen Sie nicht, häufig vernachlässigte Areale einzucremen (z. B. Nacken, Ohren, Glatze, Handrücken). Auch an die Lippen denken. Nehmen Sie die Vorsorgetermine beim Hautarzt gewissenhaft wahr. Bei neu aufgetretenen verdächtigen Hautveränderungen sollten Sie sich stets unverzüglich bei Ihrem Hautarzt vorstellen.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info">
              <p class="psemibold fontcolor-white">
                Ernährung
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                Achten Sie auf eine gesunde vitaminreiche und ausgewogene Kost. Sie sollten Ihr Gewicht im Normalbereich halten und kein Übergewicht entwickeln. Hygienischer Umgang mit Lebensmittel.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info">
              <p class="psemibold fontcolor-white">
                Zwischenmenschliches
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
                In den ersten sechs Monaten nach Transplantation sollten Sie größere Menschenansammlungen meiden. Vermeidung Sie Kontakt zu Menschen mit akuten Infektionen und Kindern mit Kinderkrankheiten. Impfungen sollten Sie immer mit Ihrem Arzt besprechen.	Im ersten halben Jahr keine Sauna- und Schwimmbadbesuche.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="info">
              <p class="psemibold fontcolor-white">
                Tiere und Pflanzen
              </p>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <p class="pregular fontcolor-white">
               Hygienische Händedesinfektion nach dem Umgang mit Tieren ist wichtig. Besondere Vorsicht ist beim Halten von Vögeln geboten: Sie sind Überträger von Infektionskrankheiten. Umgang mit den Ausscheidungen von Tieren sollten Sie vermeiden. Ihre Haustiere müssen unter regelmäßiger tierärztlicher Kontrolle stehen. Bei Arbeiten mit Erde Gummihandschuhe tragen. Arbeiten mit Kompost sollten Sie vermeiden. Eine Alternative zu Pflanzen in Blumenerde sind Hydrokulturen.
              </p>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- <information-accordion accordiontitle="Vitalwerte" accordiontext="Loremipsumdolorsit"></information-accordion> -->
      </div>
    </div>
    <foot-navigation></foot-navigation>
  </div>
</template>

<style>
  .accordion {
      width: 100%;
      color: #ffffff;
      cursor: pointer;
      border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
      text-align: left;
  }

  .accordion .card:first-of-type {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
  }

  .accordion .card:not(:first-of-type):not(:last-of-type) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
  }

  .mb-1, .my-1 {
      margin-bottom: 0.25rem!important;
  }

  .p-1 {
      padding: 0rem!important;
  }

  .card {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: transparent !important;
      background-clip: border-box;
      border-radius: 0rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
  }

  .card-header:first-child {
      border-radius: 0 !important;
  }

  .card-header {
      padding: 0.75rem 0rem;
      margin-bottom: 0;
      background-color: rgba(0,0,0,.03);
      border-bottom: 1px solid rgba(0,0,0,.125);
  }

  .btn {
      background: transparent;
      border: 0;
      outline: none;
      border-radius: 30px;
      display: inline-block;
      font-weight: 400;
      text-align: left;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      padding: 1.3rem 0rem;
      font-size: 1rem;
  }

  .btn-block {
      display: block;
      width: 100%;
  }

  .btn-info {
      color: #fff;
      background-color: transparent !important;
      border-color: transparent !important;
  }

  .btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus {
      box-shadow: none !important;
  }

  .btn-info.focus, .btn-info:focus {
      box-shadow: none !important;
  }

  .card-body {
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      padding: 0rem 0rem 1.5rem 0rem;
  }

</style>




<script>
  import router from '../router';
  import FootNavigationVue from "../components/FootNavigation.vue";

  export default {
    name: 'informationvorsorge',
    components: {
      'foot-navigation' :FootNavigationVue,
    },
    methods: {
      informationvorsorgezuinformation() {
        router.push({ path: '/information' })
      },
    },
   }; 
</script>